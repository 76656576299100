<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="关键字:">
                        <el-input v-model="filters.keyWord" @input="(e) => (filters.keyWord = utils.validForbid(e))" placeholder="项目编号/项目名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <el-select v-model="filters.areaStr" placeholder="请选择省份" filterable clearable>
                            <el-option
                                v-for="item in areaArr"
                                :key="item.id"
                                :label="item.name"
                                :value="item.code"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="">
                        <el-select v-model="filters.entyType" placeholder="项目状态" clearable>
                            <el-option
                                v-for="item in entyTypeArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
            :data="tableData"
            border
            style="width: 100%"
            v-loading="listLoading"
            ref="multipleTable"
            @current-change='currentChange'
            :highlight-current-row='true'
        >
            <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
            <!-- <el-table-column type="selection" width="45"></el-table-column> -->
            <el-table-column prop="projectNo" label="项目编号" align="center" width="155" show-overflow-tooltip></el-table-column>
          <el-table-column prop="areaName" label="所属县（区）" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="projectName" label="项目名称" align="center" show-overflow-tooltip>
                <template slot-scope='scope'>
                    <el-button @click="viewsEntyTemplate(scope.row)" type="text">{{scope.row.projectName}}</el-button>
                </template>
            </el-table-column>
	        <el-table-column prop="ddIdNodeBizType" label="业务类型" align="center" show-overflow-tooltip width="100">
		        <template slot-scope='scope'>
			        <el-tag v-if="scope.row.ddIdNodeBizType === 1" type="success">图审</el-tag>
			        <el-tag v-if="scope.row.ddIdNodeBizType === 10" type="danger">验收</el-tag>
			        <el-tag v-if="scope.row.ddIdNodeBizType === 100" type="danger">开业检查</el-tag>
		        </template>
	        </el-table-column>
	        <el-table-column prop="ddIdVenueNature" label="场所类型" align="center" show-overflow-tooltip width="100">
		        <template slot-scope='scope'>
			        <div v-for="item in ddIdVenueNature">
				        <el-tag v-if="scope.row.ddIdVenueNature === item.id"  type="success">{{ item.value }}</el-tag>
			        </div>
		        </template>
	        </el-table-column>
			<el-table-column prop="isPrequalification" label="是否预审" align="center" width="110">
			    <template slot-scope='scope'>
			        <el-tag v-if="scope.row.isPrequalification == true" type="success">是</el-tag>
			        <el-tag v-if="scope.row.isPrequalification == false" type="danger">否</el-tag>
			    </template>
			</el-table-column>
<!--            <el-table-column prop="projectContacts" label="项目联系人" align="center" width="105" show-overflow-tooltip>-->
<!--              <template slot-scope='scope'>-->
<!--                <el-button @click="projectContact(scope.row)" type="text">查看</el-button>-->
<!--              </template>-->
<!--            </el-table-column>-->
            <el-table-column prop="coefficient" label="项目系数" align="center" width="120" show-overflow-tooltip></el-table-column>
            <el-table-column prop="SendTime" label="消防设计审查申请表" align="center" width="105">
                <template slot-scope='scope'>
                    <el-button @click="viewsApplicationForm(scope.row)" type="text">查看</el-button>
                </template>
            </el-table-column>

            <!-- <el-table-column prop="SendTime" label="项目图纸" align="center" width="">
                <template slot-scope='scope'>
                    <el-button @click="viewsEntyTemplate(scope.row)" type="text">查看</el-button>
                </template>
            </el-table-column> -->

<!--            <el-table-column prop="SendTime" label="项目参与人员" align="center" width="115">-->
<!--                <template slot-scope='scope'>-->
<!--                    <el-button @click="viewsParticipants(scope.row)" type="text">查看</el-button>-->
<!--                </template>-->
<!--            </el-table-column>-->
          <el-table-column prop="SendTime" label="项目阶段明细" align="center" width="120">
            <template slot-scope='scope'>
              <el-button @click="viewStageList(scope.row)" type="text">查看</el-button>
            </template>
          </el-table-column>
<!--            <el-table-column prop="SendTime" label="初审意见(图审报告)" align="center" width="95">-->
<!--                <template slot-scope='scope'>-->
<!--                    <el-button @click="viewsPreliminaryReport(scope.row)" type="text">查看</el-button>-->
<!--                </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column prop="SendTime" label="复核意见(1...n次)" align="center" width="85">-->
<!--                <template slot-scope='scope'>-->
<!--                    <el-button @click="viewsreviewReport(scope.row)" type="text">查看</el-button>-->
<!--                </template>-->
<!--            </el-table-column>-->
            <el-table-column prop="SendTime" label="图审报告" align="center" width="85">
                <template slot-scope='scope'>
                    <el-button @click="viewsAuditReport(scope.row)" type="text">查看</el-button>
                </template>
            </el-table-column>
          <el-table-column prop="SendTime" label="留言" align="center" width="90">
            <template slot-scope='scope'>
              <el-button @click="viewsLeaveMessage(scope.row)" type="text">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <!-- 消防设计审查申请表 -->
        <el-dialog
            title="消防设计审查申请表"
            :visible.sync="projectRegisterDialog"
            v-model="projectRegisterDialog"
            :close-on-click-modal="false"
            width='1200px'
        >
            <WordTable :projectId="needData ? needData.id : 0" :needData="needData" :dataHideBtn="true"></WordTable>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="projectRegisterDialog = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 项目参与人员 -->
        <el-dialog
            title="项目参与人员"
            :visible.sync="ParticipantsDialog"
            v-model="ParticipantsDialog"
            :close-on-click-modal="false"
            width='80%'
        >
          <el-table
              :data="ParticipantsData"
              border
              style="width: 100%"
              v-loading="ParticipantsLoading"
              ref="multipleTable"
              :highlight-current-row='true'
              :cell-style="rowClass"
          >
            <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
            <el-table-column prop="participationTypeName" width='160' label="类型" align="center"
                             show-overflow-tooltip></el-table-column>
            <el-table-column prop="name" label="姓名" align="center" show-overflow-tooltip>
              <template slot-scope='scope'>
                {{ scope.row.name }}
              </template>
            </el-table-column>

            <el-table-column prop="applyStatusName" label="状态" width='240' align="center"
                             show-overflow-tooltip></el-table-column>
            <el-table-column prop="signStatus" label="签署状态" width='160' align="center"
                             show-overflow-tooltip></el-table-column>
            <el-table-column prop="overdueDate" label="到期时间(晚)" width='160' align="center"
                             show-overflow-tooltip></el-table-column>
            <el-table-column prop="completeDate" label="完成时间" width='200' align="center"
                             show-overflow-tooltip></el-table-column>
            <el-table-column prop="phoneNumber" label="手机号" width='150' align="center"
                             show-overflow-tooltip></el-table-column>
          </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="ParticipantsDialog = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 复核报告 -->
        <el-dialog
            :title="reportORidea ? '复核报告' : '复核意见'"
            :visible.sync="reviewReportDialog"
            v-model="reviewReportDialog"
            :close-on-click-modal="false"
            width='50%'
        >
            <ReviewReport :projectId="preliminaryProjectId" :reportORidea="reportORidea" @reviewReportClick="reviewReportClick"></ReviewReport>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="reviewReportDialog = false">取消</el-button>
            </div>
        </el-dialog>
<!--        &lt;!&ndash; 审查意见 &ndash;&gt;-->
<!--        <el-dialog-->
<!--            title="审查意见"-->
<!--            :visible.sync="showOpinionDialog"-->
<!--            v-model="showOpinionDialog"-->
<!--            :close-on-click-modal="false"-->
<!--            width='50%'-->
<!--        >-->
<!--            <div v-if="examinationsList.length > 0">-->
<!--                <el-form v-for="(item,index) in examinationsList" :key="index" label-width="100px" style="border-bottom: 1px solid #999;">-->
<!--                    <el-form-item label="问题描述：" style="margin-bottom: 0;">-->
<!--                        {{item.confirmProblem}}-->
<!--                    </el-form-item>-->
<!--                    <el-form-item label="所属规范：" style="margin-bottom: 0;">-->
<!--                        {{item.drStandardName}}-->
<!--                    </el-form-item>-->
<!--                    <el-form-item label="所属条文：" style="margin-bottom: 0;">-->
<!--                        {{item.drStandardItemName}}-->
<!--                    </el-form-item>-->
<!--                    <el-form-item label="图纸位置：" style="margin-bottom: 0;">-->
<!--                        {{item.locationDescription}}-->
<!--                    </el-form-item>-->
<!--                    <el-form-item label="审查内容：" style="margin-bottom: 0;">-->
<!--                        {{item.drAuditContentName}}-->
<!--                    </el-form-item>-->
<!--                </el-form>-->
<!--            </div>-->
<!--            <div v-if="!examinationsList.length > 0">-->
<!--                <div style="text-align: center;">暂无数据</div>-->
<!--            </div>-->
<!--            <div slot="footer" class="dialog-footer">-->
<!--                <el-button @click.native="showOpinionDialog = false">取消</el-button>-->
<!--            </div>-->
<!--        </el-dialog>-->
        <!-- 报告 -->
        <el-dialog
            title="报告"
            :visible.sync="preliminaryReportDialog"
            v-model="preliminaryReportDialog"
            :close-on-click-modal="false"
            width='50%'
        >
            <PreliminaryReport :projectId="preliminaryProjectId"></PreliminaryReport>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="preliminaryReportDialog = false">关闭</el-button>
            </div>
        </el-dialog>
        <!-- 图审报告 -->
        <el-dialog
            title="图审报告"
            :visible.sync="dialogReportVisible"
            v-model="dialogReportVisible"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            width='50%'
        >
            <AuditReportComponent :projectId="preliminaryProjectId"></AuditReportComponent>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="dialogReportVisible = false">关闭</el-button>
            </div>
        </el-dialog>
        <!-- 查看 -->
        <el-dialog
            title="查看"
            :visible.sync="viewsApplicationDialog"
            v-model="viewsApplicationDialog"
            :close-on-click-modal="false"
            :width='viewsApplicationWidth'
        >
            <ProjectView @activeChange="activeChange" :needData="needData"></ProjectView>
        </el-dialog>
        <!--查看联系人 -->
      <el-dialog
          title="详情"
          :visible.sync="projectContactDialog"
          v-model="projectContactDialog"
          :close-on-click-modal="false"
          :width='viewsApplicationWidth'
      >
        <el-table
            :data="needDataContact"
            border
            style="width: 100%"
            v-loading="listLoading"
            ref="multipleTable"
            @current-change='currentChange'
            :highlight-current-row='true'
        >

          <el-table-column prop="projectContacts" label="姓名" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="projectPhone" label="联系方式" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="areaName" label="所属县（区）" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="contractNo" label="关联合同编号" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="projectArea" label="图审面积(平方米)" align="center" ></el-table-column>
          <el-table-column prop="ddIdProjectStatusName" label="项目状态" align="center" ></el-table-column>
          <el-table-column prop="conclusionTime" label="归档日期" align="center" width="110"></el-table-column>
          <el-table-column prop="overdueDays" label="初审意见逾期天数" align="center" width="85">
            <template slot-scope='scope'>
              <div :class="scope.row.overdueDays>=0?'green_t':'red_t'">{{ scope.row.overdueDays }}</div>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
      <!--修改项目系数-->
      <el-dialog
          title="修改项目"
          :visible.sync="editProjectContactDialog"
          v-model="editProjectContactDialog"
          :close-on-click-modal="false"
          width='60%'
      >
        <el-form :model="editContactEntryForm" :rules="editContactEntryFormRules" ref="addNewContactEntryRef"
                 label-width="160px"
                 style='min-width:200px'>
          <el-form-item label="项目系数" prop="coefficient">
            <el-input v-model='editContactEntryForm.coefficient' clearable></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="editProjectContactDialog = false">取消</el-button>
          <el-button type="primary" @click="editContactEntrySubmit" :loading="addNewEntryLoading">确认</el-button>
        </div>
      </el-dialog>
      <!-- 项目阶段明细 -->
      <el-dialog
          title="项目阶段明细"
          :visible.sync="stageDialog"
          v-model="stageDialog"
          :close-on-click-modal="false"
          width='80%'
      >
        <el-table
            :data="stageData"
            border
            style="width: 100%"
            v-loading="stageLoading"
            ref="multipleTable"
            :highlight-current-row='true'
            :cell-style="rowClass"
        >
          <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
          <el-table-column prop="stageName" label="阶段名称" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="isPrequalification" label="是否预审" align="center" width="90">
            <template slot-scope='scope'>
              <el-tag v-if="scope.row.isPrequalification == true" type="success">是</el-tag>
              <el-tag v-if="scope.row.isPrequalification == false" type="danger">否</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="SendTime" label="参与人员" align="center" width="120">
            <template slot-scope='scope'>
              <el-button @click="viewsProjectStageUserInfo(needData.id,scope.row)" type="text">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="assignUploadTime" width='160' label="上传资料时间" align="center"
                           show-overflow-tooltip>
            <template slot-scope='scope'>
              {{ scope.row.assignUploadTime ? scope.row.assignUploadTime.substr(0, 10) : "" }}
            </template>
          </el-table-column>

          <el-table-column prop="assignUploadNodeId" label="项目图纸" align="center" width="90">
            <template slot-scope='scope'>
              <el-button @click="showProjectFile(needData.id,scope.row.assignUploadNodeId)" type="text">查看</el-button>
            </template>
          </el-table-column>

          <el-table-column prop="assignOpinionTime" width='160' label="意见提交时间" align="center"
                           show-overflow-tooltip>
            <template slot-scope='scope'>
              {{ scope.row.assignOpinionTime ? scope.row.assignOpinionTime.substr(0, 10) : "" }}
            </template>
          </el-table-column>

          <el-table-column prop="assignOpinionNodeId" label="审查意见" align="center" width="90">
            <template slot-scope='scope'>
              <el-button v-if="scope.row.assignOpinionNodeId"
                         @click="checkOpinonView(scope.row)" type="text">查看
              </el-button>
            </template>
          </el-table-column>

          <el-table-column prop="assignOpinionSummaryTime" width='160' label="意见汇总时间" align="center"
                           show-overflow-tooltip>
            <template slot-scope='scope'>
              {{ scope.row.assignOpinionSummaryTime ? scope.row.assignOpinionSummaryTime.substr(0, 10) : "" }}
            </template>
          </el-table-column>

          <el-table-column prop="reportUrl" label="查看报告" align="center" width="180">
            <template slot-scope='scope'>
              <el-button v-if="scope.row.reportUrl" @click="showReportFile(scope.row.reportUrl)" type="text">Word报告
              </el-button>
              <el-button v-if="scope.row.reportSignUrl" @click="showReportFile(scope.row.reportSignUrl)" type="text">
                签章报告
              </el-button>

            </template>

          </el-table-column>

        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="stageDialog = false">取消</el-button>
        </div>
      </el-dialog>
      <!-- 查看项目图纸 -->
      <el-dialog
          title="项目图纸"
          :visible.sync="projectFileDialog"
          v-model="projectFileDialog"
          :close-on-click-modal="false"
          :destroy-on-close="true"
          width='60%'
      >
        <div style="margin: 0 auto;">
          <el-tree
              :data="projectFileData"
              node-key="id"
              default-expand-all
              :props="projectFileProps"
              style="max-height: 450px;overflow-y: auto;"
          >
          </el-tree>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="projectFileDialog = false">取消</el-button>
        </div>
      </el-dialog>
      <!-- 查看审查意见 -->
      <el-dialog
          title="审查意见"
          :visible.sync="showOpinionDialog"
          v-model="showOpinionDialog"
          :close-on-click-modal="false"
          :destroy-on-close="true"
          width='80%'
      >
        <ShowOpinionComponent  :projectId="showOpinionProjectId" :aiDomainUrl = "aiDomainUrl"
                              :flowNodeId="showOpinionFlowNodeId"></ShowOpinionComponent>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="showOpinionDialog = false">取消</el-button>
        </div>
      </el-dialog>
      <!-- 留言 -->
      <el-dialog
          title="留言"
          :visible.sync="leaveMessageDialog"
          v-model="leaveMessageDialog"
          :close-on-click-modal="false"
          width='50%'
      >
        <div class="projectTitle">项目名称：{{ leaveMessageProjectName }}</div>
        <div v-for="(item,index) in leaveMessageArr" :key="index">
          <div>{{ item.createUserName }}<span class="titleTimer">{{ formatCreateTime(item.createTime) }}</span></div>
          <div style="margin: 5px 0px;">{{ item.content }}</div>
          <div class="replyBtn" @click="replyMessageFun(item)">回复</div>
          <div class="replyMessage" v-for="(o,i) in item.reply" :key="i">
            <div>{{ o.createUserName }}<span class="titleTimer">{{ formatCreateTime(o.createTime) }}</span></div>
            <div><span v-if="o.replyUserName"> 回复 @{{ o.replyUserName }} </span>{{ o.content }}</div>
            <!-- <div v-for="(n,a) in o.reply" :key="a">{{n.createUserName}} 回复 @{{o.createUserName}} {{n.content}}</div> -->
            <div class="replyBtn" @click="replyMessageFun(o)">回复</div>
          </div>
        </div>
        <el-input type="textarea" style="margin-top:15px;" :rows="3" placeholder="请输入留言内容"
                  v-model="leaveMessageContent">
        </el-input>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="leaveMessageDialog = false">取消</el-button>
          <el-button type="primary" @click="leaveMessageSubmit" :loading="leaveMessageLoading">发表留言</el-button>
        </div>
      </el-dialog>
      <!-- 回复留言 -->
      <el-dialog
          title="回复留言"
          :visible.sync="replyPerMessageDialog"
          v-model="replyPerMessageDialog"
          :close-on-click-modal="false"
          width='50%'
      >
        <el-input type="textarea" style="margin-top:15px;" :rows="3" :placeholder="textareaStr"
                  v-model="replyPerMessageContent"></el-input>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="replyPerMessageDialog = false">取消</el-button>
          <el-button type="primary" @click="replyPerMessageSubmit" :loading="replyPerMessageLoading">发表留言
          </el-button>
        </div>
      </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import WordTable from "../drProjectManager/wordTable";
import ShowOpinionComponent from '../checkingManager/showOpinionComponent.vue'
import { getButtonList } from "../../promissionRouter";
import {
	getByNamespace,
  getDrProjectFiledtList,
  conclusionProject,
  getProjectParticipants,
  getAreaDictionaryList,
  updateProjectInfo,
  getStageListByProjectId,
  getProjectFileInfoByFlowNodeId,
  resetDrawingReviewApi,
  getProjectMessageByProjectId, addProjectMessage
} from '@/api/api'
import ProjectView from "../drProjectManager/projectView.vue";
import PreliminaryReport from "../checkingManager/preliminaryReportComponent.vue"
import AuditReportComponent from "../checkingManager/auditReportComponent"
import ReviewReport from "../checkingManager/reviewReportComponent.vue"
import { isValidContactNumber } from '../../../util/validate'
export default {
    components:{Toolbar, WordTable, ProjectView,PreliminaryReport,ReviewReport, AuditReportComponent,ShowOpinionComponent},
    data() {
        return {
	        ddIdVenueNature:[],
          needDataContact:[],
          editContactEntryFormRules: {
            coefficient: [{
              required: true,
              message: '请输入大于等于0的有效数字，且小数点后只保留两位的有效数字',
              trigger: 'blur'
            }, { validator: isValidContactNumber }],
          },
          editContactEntryForm: {
            coefficient: ''
          },
          //项目系数编辑
          editProjectContactDialog: false,
          addNewEntryLoading: false,
            utils: '',
            buttonList: [],
            needData: [],
            filters: {
                keyWord: '',
                areaStr: '',
                entyType: '',
            },
            entyTypeArr: [
                {name: '正常',value: 56},
                {name: '冻结',value: 57},
            ],
            tableData: [],
            areaArr: [],
            listLoading: false,
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            // 消防设计审查申请表
            projectRegisterDialog: false,
            // 项目参与人员
            ParticipantsDialog: false,
            ParticipantsLoading: false,
            ParticipantsData: [],
            // 复核报告/意见
            reportORidea: true,
            reviewReportDialog: false,
            reviewTableLoading: false,
            // reviewReportData: [],
            reviewReportId: '',
            // 审查意见
            examinationsDialog: false,
            examinationsList: [
                {
                    confirmProblem: "问题描述",
                    confirmProblem1: "没有",
                    drStandardItemName: "问题描述",
                    confirmProblem2: "没有2",
                    drAuditContentName: "审查内容名称",
                },
            ],
            // 初审报告
            preliminaryReportDialog: false,
            preliminaryReportLoading: false,
            preliminaryProjectId: '',
            isQualified: [],
            historicalData: [],
            dialogReportVisible: false,
            // 项目查看
            viewsApplicationDialog: false,
          projectContactDialog:false,
            viewsApplicationWidth: '60%',
          stageDialog:false,
          stageLoading:false,
          stageData:[],
          showOpinionDialog:false,
          showOpinionProjectId:0,
	        aiDomainUrl:undefined,
          showOpinionFlowNodeId:0,
          ParticipantsDialogName:'',
          projectFileDialog:false,
          projectFileLoading:false,
          projectFileData:[],
          resetDrawingReviewLoading:false,
          projectFileProps: {
            children: 'childFiles',
            label: 'fileName',
          },
          // 留言
          leaveMessageDialog: false,
          leaveMessageLoading: false,
          leaveMessageProjectName: '',
          replyId: 0,
          leaveMessageArr: [],
          leaveMessageContent: '',
          // 回复留言
          replyPerMessageDialog: false,
          replyPerMessageLoading: false,
          replyPerMessageContent: '',
          textareaStr: '请输入回复内容',


        }
    },
    methods: {
	    async getNamespaceArr(){
		    let params = {
			    namespace:"BizSevice.DrProject.VenueNature",
		    }
		    const res = await getByNamespace(params)
		    if (res.data.success){
			    this.ddIdVenueNature = res.data.response
		    }
		    
	    },
      rowClass () {
        return 'padding:8px!important;'
      },
      // 项目阶段明细
      viewStageList (row) {
        this.stageDialog = true,
            this.stageLoading = true,
            this.stageData = []
        var params = {
          projectId: row.id
        }
        getStageListByProjectId(params).then(res => {
          var result = res.data
          if (result) {
            this.stageData = result.response;
            // console.log(this.stageData);
          } else {
            this.$message.error("加载失败！")
          }
          this.stageLoading = false
        })
      },
      // 查看意见
	    checkOpinonView(
			    row
	    ) {
		    console.log(row,'rowaaa')
		    this.showOpinionDialog = true
		    this.showOpinionProjectId =this.needData.id
		    this.aiDomainUrl = row.aiDomainUrl+'/project/qingda/'+row.aiTaskId+'/'+this.needData.projectName
		    this.showOpinionFlowNodeId = row.assignOpinionNodeId
		    
	    },
      // 查看报告
      showReportFile (file) {
        let a = document.createElement('a');
        a.href = file
        a.target = 'blank';
        document.body.append(a);
        a.click();
        a.remove();
      },
      viewsProjectStageUserInfo (projectId, row) {
        this.ParticipantsDialog = true,
            this.ParticipantsLoading = true,
            this.ParticipantsDialogName = "项目参与人员";
        this.ParticipantsData = []

        var flowNodeId = row.assignUploadNodeId
        if (row.assignOpinionNodeId) flowNodeId += "," + row.assignOpinionNodeId
        if (row.assignOpinionSummaryNodeId) flowNodeId += "," + row.assignOpinionSummaryNodeId

        var params = {
          projectId: projectId,
          nodeIds: flowNodeId
        }
        getProjectParticipants(params).then(res => {
          var result = res.data
          if (result) {
	          this.ParticipantsData = result.response.filter(item=>item.participationTypeName !== '见习工程师')
          } else {
            this.$message.error("加载失败！")
          }
          this.ParticipantsLoading = false
        })
      },
      // 查看意见
      showProjectFile (projectId, flowNodeId) {

        this.projectFileDialog = true
        this.projectFileLoading = true
        this.projectFileData = []

        var params = {
          projectId: projectId,
          flowNodeId: flowNodeId
        }
        getProjectFileInfoByFlowNodeId(params).then(res => {
          let result = res.data
          if (result.success) {
            this.projectFileData = result.response.files
            this.projectFileLoading = false
          }
        })

      },
      resetDrawingReviewSubmit () {
        this.$refs.resetDrawingReviewRef.validate((valid) => {
          if (valid) {
            this.resetDrawingReviewLoading = true;
            var params = {
              projectId: this.needData.id,
              overdueDate: this.resetDrawingReviewForm.overdueDate,
            }
            resetDrawingReviewApi(params).then(res => {
              this.resetDrawingReviewLoading = false;
              this.resetDrawingReviewDialog = false;
              var result = res.data
              if (result.success) {
                this.$message.success(result.message)
                this.getDataList();
              } else {
                this.$message.warning(result.message)
              }
            })
          }
        })
      },
      // 编辑项目系数
      handleEditContact () {
        if (this.needData) {
          this.editProjectContactDialog = true
          this.addNewEntryLoading = false
          this.editContactEntryForm = {
            coefficient: this.needData.coefficient,
          }
        } else {
          this.$message.warning("请输入难度系数！")
        }
      },
      // 编辑项目系数提交
      editContactEntrySubmit () {
        this.$refs.addNewContactEntryRef.validate((valid) => {
          if (valid) {
            this.addNewEntryLoading = true;
            var params = {
              coefficient: this.editContactEntryForm.coefficient,
              id: this.needData.id,
            }
            updateProjectInfo(params).then(res => {
              this.addNewEntryLoading = false;
              this.editProjectContactDialog = false;
              var result = res.data
              if (result.success) {
                this.$message.success(result.message)
                this.getListData();
              } else {
                this.$message.warning(result.message)
              }
            })
          }
        })
      },
        callFunction(item) {
            this[item.func].apply(this, item);
        },
        activeChange(val) {
            if(val == '2') {
                this.viewsApplicationWidth = "1200px"
            } else {
                this.viewsApplicationWidth = "60%"
            }
        },
        // 列表查询接口
        getListData(){
            var params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
                searchKey: this.filters.keyWord,
                provinceCode: this.filters.areaStr,
              projectStatus:55,
            }
            if(this.filters.entyType){
                params.conclusionStatus = this.filters.entyType;
            }
            this.listLoading = true
            getDrProjectFiledtList(params).then(res => {
                var result = res.data
                if(result.success) {
                    this.tableData = result.response.data
                    this.pages.dataCount = result.response.dataCount
                }
                this.listLoading = false
            })
        },
        // 获取省
        getAreaList() {
            var params = {
                parentId: 1,
            }
            getAreaDictionaryList(params).then(res => {
                if (res.data.success) {
                    this.areaArr = res.data.response.reverse();
                }
            })
        },
        // 查询
        getProjectList() {
            this.pages.pageIndex = 1
            this.getListData();
        },
        // 审结操作
        conclusionProject() {
            if(this.needData) {
                this.$confirm('确定审结？',"提示",{
                }).then(() => {
                    var params = {
                        projectId: this.needData.id
                    }
                    conclusionProject(params).then(res => {
                        var result = res.data
                        if(result.success) {
                            this.$message.success(result.message)
                            this.getProjectList()
                        } else {
                            this.$message.error(result.message)
                        }
                    })
                })
            } else {
                this.$message.warning("请选择需要审结项！")
            }
        },
        // 申请援助
        applyAssistance() {
            if(this.needData) {
                this.applyForSendSMSDialog = true
                this.applyForSendSMLoading = false
                this.applyRadio = ""
            } else {
                this.$message.warning("请选择需要申请援助项！")
            }
        },
        // 申请援助提交
        applyForSendSMSubmit() {
            if(!this.applyRadio) {
                this.$message.warning("请先选择申请援助原因！")
                return
            }
            var params = {
                bizId: this.needData.id,
                taskStatus: this.applyRadio
            }
            this.applyForSendSMLoading = true
            applyAssistance(params).then(res => {
                var result = res.data
                if(result.success) {
                    this.$message.success(result.message)
                    this.getProjectList()
                    this.applyForSendSMSDialog = false
                } else {
                    this.$message.error(result.message)
                }
                this.applyForSendSMLoading = false
            })
        },
        viewsEntyTemplate(row) {
            this.needData = row
            this.viewsApplicationDialog = true
        },
      projectContact(row){
          this.needData = row
          this.projectContactDialog = true
      },
        // 项目参与人员
        viewsParticipants(row) {
            this.ParticipantsDialog = true,
            this.ParticipantsLoading = true,
            this.ParticipantsData = []
            var params = {
                projectId: row.id
            }
            getProjectParticipants(params).then(res => {
                var result = res.data
                if(result) {
                    this.ParticipantsData = result.response;
                } else {
                    this.$message.error("加载失败！")
                }
                this.ParticipantsLoading = false
            })
        },
        // 初审报告
        viewsPreliminaryReport(row) {
            this.preliminaryReportDialog = true
            this.preliminaryReportLoading = false
            this.preliminaryProjectId = row.id
        },
        // 图审报告
        viewsAuditReport(row) {
            this.dialogReportVisible = true
            this.preliminaryProjectId = row.id
        },
        // 查看复核报告
        viewsreviewReport(row) {
            this.reportORidea = true
            this.reviewReportDialog = true,
            this.reviewTableLoading = true,
            this.needData = row
            this.preliminaryProjectId = row.id
        },
        // 复核报告/意见的操作查看功能
        reviewReportClick(row) {
            // 下载复核报告
            let a = document.createElement('a');
            a.href = row.finalReportPath?row.finalReportPath:row.originalReportPath
            document.body.append(a);
            a.click();
            a.remove();
        },
        // 留言
        viewsLeaveMessage(row) {
            this.leaveMessageDialog = true,
            this.leaveMessageLoading = false,
            this.needData = row
            this.leaveMessageProjectName = row.projectName
            this.leaveMessageContent = ""
            var params = {
                projectId: row.id
            }
            getProjectMessageByProjectId(params).then(res => {
                var result = res.data
                if(result.success) {
                    this.leaveMessageArr = result.response
                }
            })
        },
        // 留言提交
        leaveMessageSubmit() {
            if(!this.leaveMessageContent) {
                this.$message.warning("请输入留言内容！")
                return
            }
            var params = {
                parentId: 0,
                projectId: this.needData.id,
                content: this.leaveMessageContent,
            }
            this.leaveMessageLoading = true
            addProjectMessage(params).then(res => {
                var result = res.data
                if(result.success) {
                    this.$message.success(result.message)
                    this.leaveMessageDialog = false
                } else {
                    this.$message.error(result.message)
                }
                this.leaveMessageLoading = false
            })
        },
        // 回复留言
        replyMessageFun(row) {
            this.textareaStr = "回复" + row.createUserName
            this.replyId = row.id
            this.replyPerMessageDialog = true
            this.replyPerMessageLoading = false
        },
        // 回复留言提交
        replyPerMessageSubmit() {
            if(!this.replyPerMessageContent) {
                this.$message.warning("请输入留言内容！")
                return
            }
            var params = {
                parentId: this.replyId,
                projectId: this.needData.id,
                content: this.replyPerMessageContent,
            }
            this.replyPerMessageLoading = true
            addProjectMessage(params).then(res => {
                var result = res.data
                if(result.success) {
                    this.$message.success(result.message)
                    this.replyPerMessageDialog = false
                    var params = {
                        projectId: this.needData.id
                    }
                    getProjectMessageByProjectId(params).then(res => {
                        var result = res.data
                        if(result.success) {
                            this.leaveMessageArr = result.response
                        }
                    })
                } else {
                    this.$message.error(result.message)
                }
                this.replyPerMessageLoading = false
            })
        },
        // 消防设计审查申请表查看
        viewsApplicationForm(row) {
            this.needData = row
            this.projectRegisterDialog = true
        },
        // 点击table某一行
        currentChange(val) {
            this.needData = val
          // console.log(val,'val')
          const myArray = [];

// 使用push方法将整个对象添加到数组中
          myArray.push(val);
          this.needDataContact = myArray
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getListData();
        },
        handleSizeChange(val){
            this.pages.pageSize = val;
            this.getListData();
        },
        // 时间格式化
        formatCreateTime: function(row, column) {
            return !row || row == ""
            ? ""
            : util.formatDate.format(new Date(row), "yyyy-MM-dd hh:mm");
        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
    },
    watch: {

    },
    created(){
        this.utils = util
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
        this.getListData();
				this.getNamespaceArr()
        this.getAreaList()
    }
}
</script>

<style lang="stylus" scoped>
    .projectName {
        text-align: center;
    }
    .projectSelect {
        margin-bottom: 15px;
    }
    .projectTitle {
        margin-bottom: 15px
    }
    .replyBtn {
        margin: 10px 0;
        color: #409eff;
        cursor: pointer;
        width: 30px;
    }
    .replyMessage {
        background: #eee;
        line-height: 30px;
        padding: 5px;
        border-radius: 2px;
        margin: 5px 15px;
    }
    .titleTimer {
        margin-left: 10px;
    }
    .green_t {
        color: green;
    }
    .red_t {
        color: red;
    }
</style>
